<template>
    <window-portal :open="dataProps.show" @close="() => {
        dataProps.show = false
        if(dataProps?.closeCallback){
            dataProps?.closeCallback()
        };
    }
        ">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista Previa de Reporte de Existencias</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>
        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>
        
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1280px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 20px; margin-right: 20px;">
                                    <div>
                                        <div style="text-align: center; font-size: 12px;">
                                            <h2>{{ businessName }}</h2>
                                            <p>Reporte de Existencias a la Fecha: {{ dataProps.reportDate }}</p>
                                        </div>
                                    </div>
                                    <br>
                                    <table style="font-size: 10px; border-collapse: collapse; width: 100%; margin-top: 10px;">
                                        <template v-for="(item, index) in valuesRows[indexPage]">
                                            <tr :key="'header-' + index"  v-if="item.showWarehouseHeader">
                                                <td colspan="15" style="text-align: left; font-size: 14px; margin-top: 20px;">
                                                    <br/>
                                                    Almacén: {{ item.WarehouseName }} 
                                                </td>
                                            </tr>
                                        
                                            <tr :key="'header-columns-' + index" v-if="index === 0">
                                                <td v-if="!dataProps.data.options.oIdProducto" style="width: 80px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">ID Producto</td>
                                                <td v-if="!dataProps.data.options.oCodigoAlerno" style="width: 50px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Código alterno principal</td>
                                                <td style="border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Nombre producto</td>
                                                <td style="width: 40px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Lote</td>
                                                <td style="width: 50px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Unidad</td>
                                                <td v-if="!dataProps.data.options.oFamilia" style="width: 50px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Familia</td>
                                                <td v-if="!dataProps.data.options.oCategoria" style="width: 50px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Categoría</td>
                                                <td v-if="!dataProps.data.options.oSubcategoria" style="width: 50px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Subcategoría</td>
                                                <td style="border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Proveedores</td>
                                                <td v-if="!dataProps.data.options.oUltimaFechaC" style="width: 60px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Última fecha de compra</td>
                                                <td v-if="!dataProps.data.options.oUltimoCostoC" style="width: 50px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Último costo de compra</td>
                                                <td v-if="!dataProps.data.options.oCostos" style="width: 50px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Costo</td>
                                                <td style="width: 50px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Disponible</td>
                                                <td v-if="!dataProps.data.options.oMinimoReq" style="width: 40px; border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid; padding: 5px;">Mínimo requerido</td>
                                                <td v-if="!dataProps.data.options.oMaximosReq" style="width: 40px; border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">Máximo requerido</td>
                                                <td v-if="dataProps.data.options.aIngresoManual" style="width: 60px; border-top: 1px solid; border-bottom: 1px solid; padding: 5px; border-left: 1px solid;"></td>
                                            </tr>
                                            <tr :key="'data-row-' + index">
                                                <td v-if="!dataProps.data.options.oIdProducto" style="width: 80px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.ID_Item }}</td>
                                                <td v-if="!dataProps.data.options.oCodigoAlerno" style="width: 50px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.PrincipalBarCode }}</td>
                                                <td style="padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.ItemName }}</td>
                                                <td style="width: 40px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.Lote }}</td>
                                                <td style="width: 50px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.ItemMeasureUnit }}</td>
                                                <td v-if="!dataProps.data.options.oFamilia" style="width: 50px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.familyName }}</td>
                                                <td v-if="!dataProps.data.options.oCategoria" style="width: 50px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.categoryName }}</td>
                                                <td v-if="!dataProps.data.options.oSubcategoria" style="width: 50px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.subcategoryName }}</td>
                                                <td style="padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.Providers }}</td>
                                                <td v-if="!dataProps.data.options.oUltimaFechaC" style="width: 60px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.LastPurchaseDate }}</td>
                                                <td v-if="!dataProps.data.options.oUltimoCostoC" style="width: 50px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.LastPurchasePrice }}</td>
                                                <td v-if="!dataProps.data.options.oCostos" style="width: 50px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.AverageCostFormatted }}</td>
                                                <td style="width: 50px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.TotalStock }}</td>
                                                <td v-if="!dataProps.data.options.oMinimoReq" style="width: 40px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid;">{{ item.ItemMinimum }}</td>
                                                <td v-if="!dataProps.data.options.oMaximosReq" style="width: 40px; padding: 5px; border-bottom: 1px solid;">{{ item.ItemMaximum }}</td>
                                                <td v-if="dataProps.data.options.aIngresoManual" style="width: 60px; padding: 5px; border-left: 1px solid; border-bottom: 1px solid;"></td>
                                            </tr>
                                        </template>
                                    </table>
                                </div>
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"

export default {
    name: "PrintReporteExistencias",
    props: {
        dataProps: {
            type: Object,
        },
    },
    components: {WindowPortal},
    data() {
        return {
            loading: false,
            numPages: 0,
            valuesRows: [],
            actualWarehouse: null,
            totalContado: 0,
            totalIngresos: 0,
            totalCredito: 0,
            totalEgresos: 0,
            total: 0,
            businessName: JSON.parse( localStorage.getItem( 'user' ) ).business
        }
    },
    mounted() {
    },
    computed: {
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
        formatDateToDDMMYYYYStart() {
            if (/^\d{2}\/\d{2}\/\d{4}$/.test(this.dataProps.data.dates.start)) {
                return this.dataProps.data.dates.start;
            }
            const d = new Date(this.dataProps.data.dates.start);
            if (isNaN(d.getTime())) {
                return 'Fecha inválida'; 
            }
            const day = d.getDate().toString().padStart(2, '0');
            const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 (enero) a 11 (diciembre)
            const year = d.getFullYear();

            return `${day}/${month}/${year}`;
        },
        formatDateToDDMMYYYYEnd() {
            if (/^\d{2}\/\d{2}\/\d{4}$/.test(this.dataProps.data.dates.end)) {
                return this.dataProps.data.dates.end;
            }
            const d = new Date(this.dataProps.data.dates.end);
            if (isNaN(d.getTime())) {
                return 'Fecha inválida'; 
            }
            const day = d.getDate().toString().padStart(2, '0');
            const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 (enero) a 11 (diciembre)
            const year = d.getFullYear();

            return `${day}/${month}/${year}`;
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                }, 0);
            }
        }
    },
    methods: {
        hasTypeItem(indexPage) {
            if (this.actualWarehouse == null || indexPage === 0) {
                this.actualWarehouse = this.valuesRows[indexPage][0].WarehouseName;
                return this.actualWarehouse;
            } else {
                const currentPage = this.valuesRows[indexPage];
                const currentWarehouse = currentPage[0].WarehouseName;

                if (this.actualWarehouse !== currentWarehouse) {
                    this.actualWarehouse = currentWarehouse;
                }

                return this.actualWarehouse;
            }
        },
        filterByCategory(type, items) {
            return items.filter(item => item.Categoria === type);
        },
        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0

            let trueCount = Object.values(this.dataProps.data.options).filter(value => value === true).length;
            trueCount = this.dataProps.data.options.aIngresoManual ? trueCount - 1 : trueCount;
            let maxRowsPerPage = 0
            
            if (trueCount <= 2 ) {
                maxRowsPerPage = 27
            }
            
            if (trueCount == 3) {
                maxRowsPerPage = 28
            }

            if (trueCount == 4 ) {
                maxRowsPerPage = 32
            }

            if (trueCount >= 5) {
                maxRowsPerPage = 35
            }

            this.valuesRows[pages] = []
            this.totalContado = 0
            this.totalIngresos = 0
            this.totalCredito = 0
            this.totalEgresos = 0
            this.total = 0

            try{
                const body = this.dataProps.data.body

                body.sort((a, b) => {
                    const warehouseA = a.Warehouse || '';  
                    const warehouseB = b.Warehouse || '';

                    if (warehouseA !== warehouseB) {
                        if (warehouseA === '') return 1;
                        if (warehouseB === '') return -1;

                        return warehouseA < warehouseB ? -1 : 1;
                    }

                    /*if (a.familyName !== b.familyName) {
                        return a.familyName < b.familyName ? -1 : 1;
                    }

                    if (a.PrincipalBarCode !== b.PrincipalBarCode) {
                        return a.PrincipalBarCode < b.PrincipalBarCode ? -1 : 1;
                    }*/

                    return 0;
                });

                let lastWarehouse = null;
                for (let i = 0; i < body.length; i++) {
                    const currentItem = body[i];
                    const currentWarehouse = currentItem.Warehouse || ''; // Manejar valores vacíos

                    // Si el Warehouse cambia, inicia una nueva página
                    if (currentWarehouse !== lastWarehouse) {
                        if (rows > 0) { // Si hay filas en la página actual, inicia una nueva página
                            pages++;
                            rows = 0;
                            this.valuesRows[pages] = [];
                        }
                        lastWarehouse = currentWarehouse;
                        currentItem.showWarehouseHeader = true;
                    } else {
                        currentItem.showWarehouseHeader = false;
                    }

                    // Agregar el item a la página actual
                    this.valuesRows[pages].push(currentItem);
                    rows++;

                    // Si alcanzamos el número máximo de filas por página, crear una nueva
                    if (rows >= maxRowsPerPage) {
                        pages++;
                        rows = 0;
                        this.valuesRows[pages] = [];
                    }
                }

                
                //this.total = parseFloat(this.totalContado + this.totalIngresos - this.totalEgresos).toFixed(2)
                //this.totalContado = parseFloat(this.totalContado).toFixed(2)
                //this.totalIngresos = parseFloat(this.totalIngresos).toFixed(2)
                //this.totalCredito = parseFloat(this.totalCredito).toFixed(2)
                //this.totalEgresos = parseFloat(this.totalEgresos).toFixed(2)
            }
            catch(err) {
                alert(err)
            }
            pages = pages + 1
            this.numPages = pages
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save("CorteDeCajaDiario.pdf"); 

            this.loading = false
        },
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
