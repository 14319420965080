<template>
    <v-card class="white border-boxGlobal pa-4">
        <v-card v-if="isCarsem" flat class="pa-4 bar">
            <div  class="d-flex justify-end align-center">
                <v-btn small class="btn-add mr-1" @click.prevent="printconfig">
                    <v-icon>
                        mdi-printer
                    </v-icon>
                </v-btn>
            </div>
        </v-card>
        <v-card-title> Reporte de existencias </v-card-title>

        <v-row class="mx-1">
            <!--  <v-col cols="12" class="d-flex justify-end">
                <v-btn class="btnclean" @click="showFilter = true" >Filtros</v-btn>
            </v-col> -->
        </v-row>
        <block v-if="loading"></block>
        <VersatileFilter :isAutoScrolled="true" :columnsSearch="columsConfig" :search="search" @emptyFilter="getEarningDetail"
            @filtered="filterHandler" :key="Reloadfilter" @sendColumnsToSend="value => columnsToSend = value" @setTypedFilter="value => typedFilter = value"
            :columnfilterEndpoint="$API.inventoryReports.getInventoryFilterReportColumn" @setDatesToSend="value => datesToSend = value" :oNoStockRecords="optionsPrint.oNoStockRecords"
            :oneDateFilter="true">
            <v-row class="mt-3 ml-2 align-center">
                <v-col cols="12" md="2">
                    <v-checkbox v-model="search.showMin" @click="search.showMax = false;" label="Alerta de cantidades mínimas" />
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox v-model="search.showMax" @click="search.showMin = false;" label="Alerta de cantidades máximas" />
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox v-model="search.orderProveedor" @click="search.orderCodAlterno = false;" label="Ordenar reporte impreso por proveedor" />
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox v-model="search.orderCodAlterno" @click="search.orderProveedor = false;" label="Ordenar reporte impreso por familia" />
                </v-col>
                <v-col cols="12" md="4">
                    <v-btn class="btn-add mb-sm-2 mb-4 mb-md-0" @click="showOptionsPrint = !showOptionsPrint"><v-icon>mdi-printer</v-icon>&nbsp;Imprimir reporte de existencias</v-btn>
                </v-col>
                <v-dialog v-model="showOptionsPrint" width="60%">
                    <v-card>
                        <v-card-title>Opciones de impresión</v-card-title>
                        <v-card-text>
                            <v-row class="mt-2">
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.aIngresoManual" label="Agregar columna de ingreso manual"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oMinimoReq" label="Ocultar columna de mínimos requeridos"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oMaximosReq" label="Ocultar columna de máximos requeridos"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oCostos" label="Ocultar columna de costos"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oUltimoCostoC" label="Ocultar columna de de último costo de compra"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oUltimaFechaC" label="Ocultar columna de última fecha de compra"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oFamilia" label="Ocultar columna familia"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oCategoria" label="Ocultar columna categoría"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oSubcategoria" label="Ocultar columna subcategoría"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oCodigoAlerno" label="Ocultar columna código alterno principal"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oIdProducto" label="Ocultar columna ID Producto"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox v-model="optionsPrint.oNoStockRecords" label="Ocultar registros sin existencias"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-col cols="6" class="text-left">
                                <v-btn @click="showOptionsPrint = !showOptionsPrint" class="btndisable">Cancelar</v-btn>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-btn class="btn-add" @click="printFromOptionsDialog"><v-icon>mdi-printer</v-icon>&nbsp;Imprimir</v-btn>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
            <DxDataGrid :data-source="reportsData" :show-borders="true"
                @exporting="onExporting" :scrolling="{ useNative: true }" :column-auto-width="true">
                <DxExport :enabled="true" />

                <DxColumn data-field="ID_Item" caption="ID Producto" />
                <DxColumn data-field="PrincipalBarCode" caption="Código Alterno Principal" />
                <DxColumn data-field="ItemName" caption="Nombre Producto" />
                <DxColumn data-field="Lote" caption="Lote" />
                <DxColumn data-field="WarehouseName" caption="Almacén" />
                <DxColumn data-field="ItemMeasureUnit" caption="Unidad" />
                <DxColumn data-field="familyName" caption="Familia" />
                <DxColumn data-field="categoryName" caption="Categoría" />
                <DxColumn data-field="subactegoryName" caption="Subcategoría" />
                <DxColumn data-field="Providers" caption="Proveedores" />
                <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="LastPurchaseDate" caption="Última Fecha de compra" />
                <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="LastPurchasePrice" caption="Último Costo de compra" />
                <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="AverageCostFormatted" caption="Costo" />
                <DxColumn data-field="TotalStock" caption="Disponible" />
                <DxColumn data-field="TotalCost" caption="Costo Total" />
                <DxColumn data-field="ItemMinimum" caption="Mínimo Requerido" />
                <DxColumn data-field="ItemMaximum" caption="Máximo Requerido" />

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                <DxPaging :page-size="10" />
            </DxDataGrid>
        </VersatileFilter>

        <v-dialog v-model="showFilter" max-width="80%">
            <!-- <v-card>
            <v-card-title>
                <h3>Filtros</h3>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.ID_Item"
                            label="ID Producto"
                            outlined dense
                            />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.ItemName"
                            label="Nombre Producto"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.ItemLote"
                            label="Lote"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.providersNames"
                            label="Proveedores"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.WarehouseName"
                            label="Almacen"
                            outlined dense
                        />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-btn @click="
                    showFilter = false;
                    cleanFilter();" >Limpiar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                 @click="
                 showFilter = false;
                 getFilterEarningDetail()" >Filtrar</v-btn>
            </v-card-actions>

        </v-card> -->
        </v-dialog>

        <div>
            <component v-for="win in windowsList" :is="win.component" :key="win.key" :dataProps="win.props" />
        </div>

        <PrintReporteExistencias :dataProps="print2"></PrintReporteExistencias>
    </v-card>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxExport,
    DxPager,
} from "devextreme-vue/data-grid";
import VersatileFilter from "@/components/VersatileFilter";
import { printToExcel } from "@/helpers/printToexcel";
//import SubPrint from "../partial/SubPrint.vue";
//import GeneralFilter from  "@/components/GeneralFilter";
import Block from "@/components/Block";
import PrintStockSummary from "../../../components/ReportsAndPrintings/AccountigReports/PrintStockSummary";
import PrintReporteExistencias from '../../../components/ReportsAndPrintings/PrintReporteExistencias.vue';

export default {
    name: "InventoryReport",
    components: {
        Block,
        DxDataGrid,
        DxColumn,
        VersatileFilter,
        DxPaging,
        DxExport,
        DxPager,
        PrintStockSummary,
        PrintReporteExistencias,
       // SubPrint,
    },
    props: ["win", "datafilter", "business", "isCarsem"],
    data() {
        return {
            loading: false,
            columnsToSend: '[]',
            datesToSend: {},
            typedFilter: '',
            panel: 0,
            reportsData: [],
            start: null,
            end: null,
            summary: {
                totalUnitSales: 0,
                totalCostSales: 0,
            },
            search: {
                filter: null,
                showMin: false,
                showMax: false,
                orderCodAlterno: false,
                orderProveedor: false
            },
            Reloadfilter: 0,
            showFilter: false,
            filterParams: {
                ID_Item: null,
                ItemName: null,
                ItemLote: null,
                providersNames: null,
                WarehouseName: null,
            },
            print: {
                show: false,
                data: [],
                header: {},
            },
            print2: {
                show: false,
                data: [],
                header: {},
            },
            windowsList: [

            ],
            columsConfig: [
                {
                    name: "ID_Warehouse",
                    title: "Almacenes",
                    value: "",
                    type: "select",
                    options: [],
                },
                // {
                //     name: "idproviders",
                //     title: "Proveedores",
                //     value: "",
                //     type: "select",
                //     options: [],
                // },
                {
                    name: "family",
                    title: "Familia",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "category",
                    title: "Categoría",
                    value: '',
                    parent: 'Id_family',
                    type: "select",
                    options: [],
                },
                {
                    name: "subcategory",
                    parent: 'category',
                    title: "Subcategoría",
                    value: '',
                    type: "select",
                    options: [],
                },
                {
                    name: "Lote",
                    title: "Lote",
                    value: "",
                    type: "select",
                    options: [],
                },
            ],
            showOptionsPrint: false,
            optionsPrint: {
                aIngresoManual: true,
                oMinimoReq: true,
                oMaximosReq: true,
                oCostos: true,
                oUltimoCostoC: true,
                oUltimaFechaC: true,
                oFamilia: false,
                oCategoria: false,
                oSubcategoria: false,
                oCodigoAlerno: false,
                oIdProducto: false,
                oNoStockRecords: JSON.parse(localStorage.getItem("user")).keymaster == 'fa6646a6d6ef0c5f347a240270f49f38' ? true : false,
            },
            keyMaster: JSON.parse(localStorage.getItem("user")).keymaster,
        };
    },
    watch: {
        "search.showMin"() {
            this.getEarningDetail()
        },
        "search.showMax"() {
            this.getEarningDetail()
        },
        "search.orderCodAlterno"() {
            this.getEarningDetail()
        },
        "search.orderProveedor"() {
            this.getEarningDetail()
        },
    },
    methods: {
        async printFromOptionsDialog() {
            this.loading = true;
            this.showOptionsPrint = !this.showOptionsPrint;

            if( this.optionsPrint.oNoStockRecords ) {
                const result = await this.$API.inventoryReports.getInventoryFilterReportColumn({
                    extraParam1: null, filter : this.columnsToSend, business: this.search.business, branch: this.search.branch, search: JSON.stringify(this.search),
                    like: this.filter, selectedItem: null,
                    dates: JSON.stringify( {
                        ...this.datesToSend
                    }),
                    hideNoStockRecords: this.optionsPrint.oNoStockRecords
                });

                this.reportsData = result;
            } else {
                const result = await this.$API.inventoryReports.getInventoryFilterReportColumn({
                    extraParam1: null, filter : this.columnsToSend, business: this.search.business, branch: this.search.branch, search: JSON.stringify(this.search),
                    like: this.filter, selectedItem: null,
                    dates: JSON.stringify( {
                        ...this.datesToSend
                    }),
                    hideNoStockRecords: this.optionsPrint.oNoStockRecords
                });

                this.reportsData = result;
                
            }

            this.loading = false;
            this.print2 = {
                show: true,
                data: {
                    body: this.reportsData,
                    options: this.optionsPrint
                },
                reportDate: this.datesToSend.oneDate
            }
        },
        getEarningDetail() {
            this.loading = true;
            this.$API.inventoryReports
                .getInventoryReport(this.search)
                .then((response) => {
                    this.loading = false;
                    this.reportsData = response;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        onExporting(e) {
            printToExcel(e, "Reporte de Existencias");
        },
        filterHandler(response) {
            this.reportsData = response;
        },
        cleanFilter() {
            this.filterParams = {
                ID_Item: null,
                ItemName: null,
                ItemLote: null,
                providersNames: null,
                WarehouseName: null,
            };
            this.getEarningDetail();
        },
        getFilterEarningDetail() {
            this.loading = true;
            this.$API.inventoryReports
                .getInventoryFilterReport(this.filterParams)
                .then((response) => {
                    this.loading = false;
                    this.reportsData = response;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        assignParams() {
            if (this.datafilter) {
                this.columsConfig[0].options = this.datafilter.Warehouses;
                this.columsConfig[1].options = this.datafilter.Families;
                this.columsConfig[2].options = this.datafilter.Categories;
                this.columsConfig[3].options = this.datafilter.SubCategories;
                this.columsConfig[4].options = this.datafilter.batches;
                this.Reloadfilter += 1;
            }
        },
        printconfig() {
          
            this.print.show = true;
            this.print.data.body = this.reportsData;
            let summary = {
                available: 0,
                totalCostSales: 0,
            };
            this.reportsData.forEach((element) => {
                if (element.TotalStock > 0)
                summary.available += element.TotalStock;
                if (element.TotalStock > 0 && element.LastPrice != undefined && !isNaN(element.LastPrice))
                summary.totalCostSales += element.TotalStock * element.LastPrice;
               
            });
            this.print.data.summary = summary;
            this.print.header.title = "Reporte de existencias"
            const date = new Date();
            this.print.header.business = this.business;
            this.print.header.cutDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
            this.windowsList.push({
                component: PrintStockSummary,
                props: this.print,              
                key: new Date().getTime(),
            });

        },
    },
    mounted() {
        this.assignParams()
    },
};
</script>

<style scoped>
.bar {
    width: 100%;
    background-color: rgb(60, 60, 60) !important;
    color: white !important;
    border-style: solid;
    border-color: black;
    border-radius: 15px !important;
    margin-top: 1rem !important;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}
</style>